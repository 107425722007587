import { useState } from "react";
import "../../../../../styles/table_declaracoes.css";

export default function ListFilesTypeAndSubTypes(props) {
  let anoAtual = new Date().getFullYear();
  const { files, resultSacop } = props;
  const [ano, setAno] = useState(anoAtual);
  const [limit, setLimit] = useState(4);

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  function converterData(dataString) {
    if (!dataString) return "";
    const data = new Date(dataString);
    data.setMinutes(data.getTimezoneOffset());
    return data.toLocaleDateString("pt-BR");
  }

  function parseDate(date) {
    if (!date) return null;
    if (date.includes("/")) {
      const [day, month, year] = date.split("/");
      return new Date(`${year}/${month}/${day}`);
    }
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  }

  function nameOfMonth(date) {
    const parsedDate = parseDate(date);
    if (!parsedDate) return "";
    let month = parsedDate.getMonth();
    if (month < 0 || month > 11) return "";
    return months[month];
  }

  function groupByMonthAndYear(data) {
    const grouped = {};

    data.forEach((item) => {
      const date = converterData(item.DATA_PUBLICACAO) || item.DATA;
      const parsedDate = parseDate(date);
      if (!parsedDate) return;

      const monthYearKey = `${
        months[parsedDate.getMonth()]
      }-${parsedDate.getFullYear()}`;

      if (grouped[monthYearKey]) {
        grouped[monthYearKey].NUMERO_INSTRUMENTO.push(formatItem(item));
      } else {
        grouped[monthYearKey] = {
          ...item,
          NUMERO_INSTRUMENTO: [formatItem(item)],
          monthYear: monthYearKey,
        };
      }
    });

    return Object.values(grouped);
  }

  const groupedResultSacop = groupByMonthAndYear(resultSacop);

  const combinedData = [...files, ...groupedResultSacop].sort(
    (a, b) =>
      parseDate(b.DATA || b.DATA_PUBLICACAO) -
      parseDate(a.DATA || a.DATA_PUBLICACAO)
  );

  function formatItem(item) {
    let codigoItem = item.CODIGO || "";
    let id = item.ID;
    codigoItem += item.NUMERO_INSTRUMENTO || "";

    if (
      item.NUMERO_INSTRUMENTO &&
      !item.NUMERO_INSTRUMENTO.includes(
        converterData(item.DATA_PUBLICACAO).split("/")[2]
      )
    ) {
      codigoItem += converterData(item.DATA_PUBLICACAO).split("/")[2];
    }

    if (!item.NUMERO_INSTRUMENTO) {
      codigoItem += `i${item.NUMERO_PROCESSO || ""}${
        converterData(item.DATA_ENVIO).split("/")[2] || ""
      }`;
    }

    return {
      codigoItem,
      id,
      DATA_PUBLICACAO: converterData(item.DATA_PUBLICACAO),
    };
  }

  return (
    <div className="container_dispensa">
      <nav>
        <button
          className={ano === anoAtual ? "btn_ativo" : "btn_desativado"}
          onClick={() => setAno(anoAtual)}
          disabled={ano === anoAtual}
          style={{
            borderRadius: "10px 0 0 0",
          }}
        >
          2024
        </button>
        <button
          className={ano === anoAtual - 1 ? "btn_ativo" : "btn_desativado"}
          onClick={() => setAno(anoAtual - 1)}
          disabled={ano === anoAtual - 1}
        >
          2023
        </button>
        <button
          className={ano === anoAtual - 2 ? "btn_ativo" : "btn_desativado"}
          onClick={() => setAno(anoAtual - 2)}
          disabled={ano === anoAtual - 2}
        >
          2022
        </button>
        <button
          className={ano === anoAtual - 3 ? "btn_ativo" : "btn_desativado"}
          onClick={() => setAno(anoAtual - 3)}
          disabled={ano === anoAtual - 3}
          style={{
            borderRadius: "0 10px 0 0",
          }}
        >
          2021
        </button>
        {/* <button>Exercícios anteriores</button> */}
      </nav>
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th>COMPETÊNCIA</th>
            <th>OBJETO</th>
            <th>MODALIDADE</th>
            <th>AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {combinedData
            .filter(
              (item) =>
                (item.DATA_PUBLICACAO && item.DATA_PUBLICACAO.includes(ano)) ||
                (item.DATA && item.DATA.includes(ano))
            )
            .map((item) => (
              <tr key={item.ID}>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  {nameOfMonth(
                    item.DATA || converterData(item.DATA_PUBLICACAO)
                  )}
                </td>
                <td>
                  {item.TITULO || (
                    <div className="container_item_codigo">
                      {item.NUMERO_INSTRUMENTO.slice(0, limit).map((numero) => (
                        <a
                          key={numero.id}
                          href={`/licitacoes-e-contratos/detalhes/${numero.id}`}
                          className="item_id"
                        >
                          <img src="/icons/hammer.svg" alt="hammer_icon" />
                          {numero.codigoItem}
                        </a>
                      ))}
                    </div>
                  )}
                </td>
                <td>
                  <div className="tipo_container">
                    <b>{item.TIPO && item.TIPO}</b>
                    <p>{item.SUBTIPO || ""}</p>
                  </div>
                </td>
                <td>
                  <div className="center_div_actions">
                    {!item.ARQUIVO &&
                      item.NUMERO_INSTRUMENTO.length >= 5 &&
                      limit != item.NUMERO_INSTRUMENTO.length && (
                        <button
                          onClick={() =>
                            setLimit(item.NUMERO_INSTRUMENTO.length)
                          }
                          className="btn"
                        >
                          Ver mais
                        </button>
                      )}
                    {!item.ARQUIVO &&
                      item.NUMERO_INSTRUMENTO.length >= 5 &&
                      limit == item.NUMERO_INSTRUMENTO.length && (
                        <button onClick={() => setLimit(4)} className="btn">
                          Ver menos
                        </button>
                      )}
                    {item.ARQUIVO && (
                      <a
                        className="btn_baixar"
                        href={`https://administracaopublica.com.br/downloads?n=${item.CNPJ.replace(
                          /\//g,
                          ""
                        )
                          .replace(/\./g, "")
                          .replace(/-/g, "")}&a=${item.ARQUIVO}`}
                        target="_blank"
                      >
                        Baixar
                      </a>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
